import React, { FC, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import {
  Box,
  Grid,
  IconButton,
  CircularProgress,
  TablePagination,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@material-ui/core";
import MaterialTable, { Column, MTableBodyRow, MTableHeader } from "material-table";
import { PlayCircleFilled } from "@material-ui/icons";
import useStyles from "styles/studyListStyles";
import {
  NL_EXTENSION_EVENTS,
  ROWS_PER_PAGE_OPTIONS,
  DEFAULT_ROWS_PER_PAGE,
  TABLE_HEIGHT_OFFSET,
} from "utils/constants";
import { checkExtension, sendExtensionMessage } from "utils/extension";
import SearchFilter from "components/Shared/Filter/SearchFilter";
import ModalitySearchFilter from "components/Shared/Filter/ModalitySearchFilter";
import { TABLE_ICONS } from "utils/icons";
import { useApp } from "hooks/useApp";
import AutoCompleteView from "components/Shared/AutoCompleteView";
import { LastUpdated } from "components/Shared/LastUpdated";
import { UserAutoCompleteOptionView } from "components/StudyList/UserAutoCompleteOptionView";
import { updateStudy } from "api/study";
import { addBookmark, deleteBookmark } from "api/bookmark";
import { PatientJacketDialog } from "components/StudyList/PatientJacket/PatientJacketDialog";
import { StudyMenuButtons } from "components/StudyList/StudyMenuButtons";
import { StudyStatusButtons } from "components/StudyList/StudyStatusButtons";
import { BookmarkStudy } from "types/models/Bookmark";
import { Study } from "types/models/Study";
import { StyledBadge } from "components/Shared/StyledBadge";
import { UserFieldOption } from "types/UserFieldOption";
import { User } from "types/models/User";
import { ContextMenuState } from "types/ContextMenuState";
import { Patient } from "types/models/Patient";
import useWindowDimensions from "hooks/useWindowDimensions";
import FileUpload from "components/FileUpload";
import { useAuth, useStudy } from "state/selector";
import { updateUser } from "api/user";
import useUsers from "hooks/useUsers";
import { useAppTheme } from "providers/AppThemeProvider";

type PriorityRendererProps = {
  value: string;
};

export const PriorityRenderer: FC<PriorityRendererProps> = ({ value }: PriorityRendererProps) => {
  const { theme } = useAppTheme();

  if (value === "R") {
    return (
      <span>
        <StyledBadge background={theme.palette.success.dark} variant="dot" />
        Routine
      </span>
    );
  }
  if (value === "U") {
    return (
      <span>
        <StyledBadge background={theme.palette.info.dark} variant="dot" />
        Urgent
      </span>
    );
  }
  if (value === "A") {
    return (
      <span>
        <StyledBadge background={theme.palette.warning.dark} variant="dot" />
        ASAP
      </span>
    );
  }
  if (value === "S") {
    return (
      <span>
        <StyledBadge background={theme.palette.error.dark} variant="dot" />
        STAT
      </span>
    );
  }
  return (
    <span>
      <StyledBadge background={theme.palette.warning.light} variant="dot" />
      Unknown
    </span>
  );
};

const DEFAULT_COLUMN_NAMES = [
  "due",
  "priority",
  "date",
  "patient__name",
  "modalities",
  "mrn",
  "institution_name",
  "referring_physician",
  "assigned_to__name",
];

function arraysEqual(arr1: string[], arr2: string[]) {
  if (arr1.length !== arr2.length) return false;

  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();

  return sortedArr1.every((value, index) => value === sortedArr2[index]);
}

type StudyListTableProps = {
  studies: Study[];
  bookmarkStudies: BookmarkStudy[];
  count: number;
  isLoading: boolean;
  onRefresh: () => void;
  onBookmarkRefresh: () => void;
};

const StudyListTable: FC<StudyListTableProps> = ({
  studies,
  bookmarkStudies,
  count,
  isLoading,
  onRefresh,
  onBookmarkRefresh,
}) => {
  const classes = useStyles();
  const { theme } = useAppTheme();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { userFieldOptions } = useUsers();
  const { loadedAt } = useStudy();
  const {
    navigation: { query, processChange, processPageSizeChange },
  } = useApp();
  const { height } = useWindowDimensions();
  const isWideScreen = useMediaQuery("(min-width:1080px)");
  const [patient, setPatient] = useState<Patient | undefined>(undefined);
  const [tableHeight, setTableHeight] = useState(window.innerHeight - TABLE_HEIGHT_OFFSET);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const rowPerPage = Number(query.page_size) || DEFAULT_ROWS_PER_PAGE;
  const page = Number(query.page) || 1;
  const [contextMenuState, setContextMenuState] = useState<ContextMenuState>({});
  const [selectedStudy, setSelectedStudy] = useState<Study | undefined>();
  const selectedStudyBookmark = selectedStudy && bookmarkStudies.find(study => study.id === selectedStudy.id);

  const [columnNames, setColumnNames] = useState<string[]>(
    user?.worklist_columns && arraysEqual(user.worklist_columns, DEFAULT_COLUMN_NAMES)
      ? user.worklist_columns
      : DEFAULT_COLUMN_NAMES
  );
  const orderBy = useMemo(
    () => columnNames.findIndex(column => column === query.ordering || `-${column}` === query.ordering),
    [columnNames, query.ordering]
  );
  const orderDirection = useMemo(
    () => (orderBy >= 0 && (query.ordering as string).startsWith("-") ? "desc" : "asc"),
    [orderBy, query.ordering]
  );

  useEffect(() => {
    if (height !== tableHeight) {
      setTableHeight(height - TABLE_HEIGHT_OFFSET);
    }
  }, [height, tableHeight]);

  const handleDoubleClickRow = (studyId: string) => {
    if (!checkExtension()) {
      navigate(`/study/${studyId}`);
    }
  };

  const handleClick = (e: any, studyId: string) => {
    if (e.detail !== 1) {
      return;
    }
    if (!sendExtensionMessage({ type: NL_EXTENSION_EVENTS.NEW_LANTERN_VIEWER_OPENED, studyId })) {
      if (e.ctrlKey || e.metaKey) {
        window.open(`/study/${studyId}`, "_blank");
      }
    }
  };

  const handleShowPatientJacketDialog = (rowData: Study) => {
    setPatient(rowData.patient);
    setDialogOpen(true);
  };

  const updateAssignedTo = async (studyId: string, userId: string) => {
    await updateStudy(studyId, { assigned_to: userId ?? "" });
    onRefresh();
  };

  const handleContextMenuClose = () => {
    setContextMenuState({});
  };

  const handleContextMenuAddBookmark = () => {
    if (selectedStudyBookmark) {
      deleteBookmark(selectedStudyBookmark.bookmarkId).then(() => onBookmarkRefresh());
    } else {
      addBookmark({ study: selectedStudy?.id }).then(() => onBookmarkRefresh());
    }

    handleContextMenuClose();
  };

  const getTableColumn = (column: string): Column<any> => {
    if (column === "due") {
      return {
        title: "Due",
        field: "due",
        filterComponent: () => <></>,
      };
    }
    if (column === "priority") {
      return {
        title: "Priority",
        field: "name",
        filterComponent: () => <SearchFilter field="priority" searchType="match" inputType="dropdown" />,
        render: (rowData: any) => <PriorityRenderer value={rowData.priority} />,
      };
    }
    if (column === "modalities") {
      return {
        title: "Modalities",
        field: "modalities",
        filterComponent: () => <ModalitySearchFilter />,
        render: (rowData: Study) => rowData.modalities.join("\\"),
      };
    }
    if (column === "mrn") {
      return {
        title: "MRN",
        field: "mrn",
        filterComponent: () => <SearchFilter field="mrn" searchType="match" />,
      };
    }
    if (column === "date") {
      return {
        title: "Study",
        field: "description",
        filterComponent: () => <SearchFilter field="description" searchType="contains" />,
        render: (rowData: any) => (
          <>
            <span>{rowData.description || "-"}</span>
            <br />
            <span>
              {rowData.date} {rowData.time?.substring(0, 5)}
            </span>
          </>
        ),
      };
    }
    if (column === "institution_name") {
      return {
        title: "Site",
        field: "institution_name",
        filterComponent: () => <SearchFilter field="institution_name" searchType="contains" />,
      };
    }
    if (column === "patient__name") {
      return {
        title: "Patient",
        field: "patient",
        filterComponent: () => <SearchFilter field="patient__name" searchType="contains" />,
        render: (rowData: any) => (
          <>
            {rowData.patient && (
              <>
                <span>{rowData.patient.name || "-"}</span>
                <br />
                <span>
                  Out | {rowData.patient.age || "-"} | {rowData.patient.sex || "-"}
                </span>
                <IconButton
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleShowPatientJacketDialog(rowData);
                  }}
                  size="small"
                  data-testid="study-list-patient-button"
                >
                  <PlayCircleFilled />
                </IconButton>
              </>
            )}
          </>
        ),
      };
    }
    if (column === "referring_physician") {
      return {
        title: "Referring",
        field: "referring_physician",
        filterComponent: () => <SearchFilter field="referring_physician" searchType="contains" />,
      };
    }
    return {
      title: "Assigned To",
      field: "assigned_to",
      // align: "justify",
      filterComponent: () => <SearchFilter field="assigned_to__name" searchType="contains" />,
      render: (rowData: any) => (
        <div
          className={classes.assignedToRow}
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <AutoCompleteView
            value={(rowData.assigned_to as User)?.id}
            options={userFieldOptions}
            showPlaceholder
            freeSolo
            onChange={value => updateAssignedTo(rowData.id, value as string)}
            renderOption={option => <UserAutoCompleteOptionView option={option as UserFieldOption} />}
            renderTags={tagValues =>
              tagValues.map((option, index) => (
                <UserAutoCompleteOptionView
                  option={option as UserFieldOption}
                  key={`tag-${index}`}
                  hideName
                  showTooltip
                />
              ))
            }
          />
        </div>
      ),
    };
  };

  return (
    <Grid container spacing={3} className={classes.studyListTable} data-testid="study-list-container">
      <Grid item xs={12}>
        <MaterialTable<Study>
          icons={TABLE_ICONS}
          isLoading={isLoading}
          components={{
            OverlayLoading: () => (
              <div className={classes.overlayLoading}>
                <CircularProgress style={{ color: "white" }} />
              </div>
            ),
            Actions: () => (
              <div className={classes.studyListActions}>
                <StudyMenuButtons />
                <SearchFilter field="search" searchType="match" inputType="search" />
              </div>
            ),
            Pagination: props => (
              <div className={classes.studyListActions} style={{ alignItems: "center" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <LastUpdated lastUpdatedTime={loadedAt} onRefresh={onRefresh} />
                </Box>
                <TablePagination
                  {...props}
                  rowsPerPage={rowPerPage}
                  page={page - 1}
                  count={count}
                  onChangePage={(e, pageIndex: number) => {
                    processChange("page", pageIndex + 1);
                  }}
                  onChangeRowsPerPage={event => {
                    props.onChangeRowsPerPage(event);
                    processPageSizeChange(event.target.value);
                  }}
                />
              </div>
            ),
            Header: props => <MTableHeader {...props} orderBy={orderBy} orderDirection={orderDirection} />,
            Row: props => (
              <MTableBodyRow
                {...props}
                onDoubleClick={() => handleDoubleClickRow(props.data.id)}
                onRowClick={(e: any) => handleClick(e, props.data.id)}
                onContextMenu={(e: MouseEvent) => {
                  e.preventDefault();
                  setSelectedStudy(props.data);
                  setContextMenuState({
                    mouseX: e.clientX - 2,
                    mouseY: e.clientY - 4,
                  });
                }}
              />
            ),
          }}
          onOrderChange={(columnIndex, direction) => {
            processChange(
              "ordering",
              columnIndex === -1
                ? ""
                : direction === "asc"
                ? `${columnNames[columnIndex]}`
                : `-${columnNames[columnIndex]}`
            );
          }}
          onColumnDragged={(sourceIndex, destinationIndex) => {
            const newColumnNames = [...columnNames];
            const [removed] = newColumnNames.splice(sourceIndex, 1);
            newColumnNames.splice(destinationIndex, 0, removed);
            setColumnNames(newColumnNames);
            updateUser(user?.id, { worklist_columns: newColumnNames });
          }}
          columns={columnNames.map(column => getTableColumn(column)) as any}
          data={studies.map(item => ({ ...item, referring_physician: item.referring_physician || "-" }))}
          title={<StudyStatusButtons />}
          options={{
            filtering: true,
            minBodyHeight: tableHeight,
            maxBodyHeight: tableHeight,
            pageSize: rowPerPage,
            pageSizeOptions: ROWS_PER_PAGE_OPTIONS,
            headerStyle: {
              backgroundColor: theme.palette.primary.dark_opaque,
            },
            tableLayout: isWideScreen ? "auto" : "fixed",
            thirdSortClick: true,
            search: false,
            draggable: true,
          }}
        />

        <PatientJacketDialog open={dialogOpen} onClose={() => setDialogOpen(false)} patient={patient} />
        <Menu
          keepMounted
          data-testid="study-list-menu"
          className={classes.contextMenu}
          open={!!contextMenuState.mouseY}
          onClose={handleContextMenuClose}
          anchorReference="anchorPosition"
          anchorPosition={
            !!contextMenuState.mouseY && !!contextMenuState.mouseX
              ? { top: contextMenuState.mouseY, left: contextMenuState.mouseX }
              : undefined
          }
        >
          <MenuItem onClick={handleContextMenuAddBookmark}>
            {selectedStudyBookmark ? "Unbookmark" : "Bookmark"}
          </MenuItem>
        </Menu>
      </Grid>
    </Grid>
  );
};

export default StudyListTable;
